import type { HTMLProps } from "react";

export function ViewportCentre({
	className = "",
	...rest
}: HTMLProps<HTMLDivElement>) {
	return (
		<div
			{...rest}
			className={`${className} flex flex-col justify-center py-24 lg:min-h-[60vh]`}
		/>
	);
}

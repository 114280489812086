import { json } from "@remix-run/cloudflare";
import { Link, useLoaderData } from "@remix-run/react";
import { shuffle } from "lodash-es";
import { type HTMLProps, forwardRef, useEffect, useRef, useState } from "react";
import { useInViewport } from "react-in-viewport";
import { Layout } from "../components/Layout";
import { TravelPolaroidStack } from "../components/TravelPolaroidStack";
import { ViewportCentre } from "../components/ViewportCentre";
import {
	type TravelPost,
	getPosts,
	stripPostsForListing,
} from "../data/travelPosts";
// TODO: Discourage direct import of `getImgMeta` since it will dump all images onto the page.
// Get them in loader instead...
import { getImgMeta } from "../lib/imageUtils";
import type { SitemapDataFunction } from "../lib/sitemapUtils";

export const sitemap: SitemapDataFunction = () => ({
	pathname: "/",
	title: "Daniel Levett",
	sitemapTitle: "Home",
	description:
		"London-based full-stack web developer experienced in creating and maintaining JavaScript applications.",
	order: 0,
});

export async function loader() {
	const posts = stripPostsForListing(getPosts());
	const random3TravelPosts = shuffle(posts).slice(0, 3) as [
		TravelPost,
		TravelPost,
		TravelPost,
	];

	return json({
		random3Posts: random3TravelPosts,

		images: {
			// TODO: Find a nicer pattern than this. This is only a POC. This whole `getImgMeta` thing needs simplifying
			"/images/home/regex-robin.jpg": getImgMeta(
				"/images/home/regex-robin.jpg",
				"medium",
			),
			"/images/home/duration-fns.jpg": getImgMeta(
				"/images/home/duration-fns.jpg",
				"medium",
			),
			"/images/home/json-tool.jpg": getImgMeta(
				"/images/home/json-tool.jpg",
				"medium",
			),
		},
	});
}

export default function Home() {
	return (
		<Layout>
			<main>
				<SectionHero />
				<SectionProjects />
				<SectionTravel />
			</main>
		</Layout>
	);
}

function SectionHero() {
	return (
		<ViewportCentre>
			<header className="text-center">
				<h1 className="font-bold text-4xl">Daniel Levett</h1>
				<div className="mt-2">Software developer from London</div>
				<ul className="mx-auto mt-5 flex justify-center gap-5">
					<li>
						<a href="https://github.com/dlevs">GitHub</a>
					</li>
					<li>
						<a href="https://www.linkedin.com/in/daniellevett/">LinkedIn</a>
					</li>
				</ul>
			</header>
		</ViewportCentre>
	);
}

function SectionProjects() {
	const { images } = useLoaderData<typeof loader>();
	const [isSeen, setIsSeen] = useState(false);
	const visibleRef = useRef<HTMLAnchorElement>(null);
	const { inViewport } = useInViewport(visibleRef);

	useEffect(() => {
		if (!isSeen && inViewport) {
			setIsSeen(true);
		}
	}, [inViewport, isSeen]);

	return (
		<ViewportCentre>
			<section className="grid gap-14 lg:grid-cols-[7fr_5fr] lg:gap-20">
				<div className="flex flex-col justify-center text-center lg:order-1">
					<h2 className="font-semibold text-2xl">Projects</h2>
					<p className="mt-2">I make web apps, libraries and plugins.</p>
					<a
						href="https://github.com/dlevs"
						className="mt-3 inline-block"
						ref={visibleRef}
					>
						See my projects on github.
					</a>
				</div>
				<div className="relative lg:order-0">
					<ProjectImg
						{...images["/images/home/duration-fns.jpg"]}
						show={isSeen}
						className="absolute"
						alt="duration-fns npm package screenshot"
					/>
					<ProjectImg
						{...images["/images/home/regex-robin.jpg"]}
						show={isSeen}
						className="absolute mt-[10%] ml-[15%] delay-[300ms]"
						alt="Regex Robin VSCode extension screenshot"
					/>
					<ProjectImg
						show={isSeen}
						{...images["/images/home/json-tool.jpg"]}
						className="relative mt-[20%] ml-[30%] delay-[600ms]"
						alt="JSON Tool VSCode extension screenshot"
					/>
				</div>
			</section>
		</ViewportCentre>
	);
}

function SectionTravel() {
	const { random3Posts } = useLoaderData<typeof loader>();

	return (
		<ViewportCentre>
			<section className="grid gap-14 lg:grid-cols-[5fr_7fr] lg:gap-20">
				<div className="flex flex-col justify-center text-center">
					<h2 className="font-semibold text-2xl">Travel blog</h2>
					<p className="mt-2">I take photos of things I see.</p>
					<Link to="/travel" className="mt-3 inline-block">
						See places I’ve visited.
					</Link>
				</div>
				<div className="mx-auto max-w-2xl">
					<TravelPolaroidStack posts={random3Posts} />
				</div>
			</section>
		</ViewportCentre>
	);
}

const ProjectImg = forwardRef<
	HTMLImageElement,
	HTMLProps<HTMLImageElement> & { show: boolean }
>(function ProjectImg({ className = "", show, alt, ...rest }, ref) {
	const opacity = show ? "opacity-100" : "opacity-0";
	const translate = show ? "translate-y-0" : "translate-y-1/2";

	return (
		<img
			ref={ref}
			{...rest}
			alt={alt}
			className={`${className} ${opacity} ${translate} max-w-[70%] rounded border shadow-xl transition-all duration-[2000ms] ease-out`}
		/>
	);
});
